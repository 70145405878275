/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EventRuleEntity } from './EventRuleEntity';
import type { TherapistEntity } from './TherapistEntity';
export type EventCategoryEntity = {
    id: number;
    name: string;
    description: string | null;
    color: string;
    duration: number;
    price: number;
    outageFeeEnabled: boolean;
    outageFeeHours: number | null;
    outageFeePrice: number | null;
    currency: EventCategoryEntity.currency;
    location: EventCategoryEntity.location;
    isPublic: boolean;
    deleted: boolean;
    rules: Array<EventRuleEntity>;
    therapist: TherapistEntity;
};
export namespace EventCategoryEntity {
    export enum currency {
        EUR = 'EUR',
        CHF = 'CHF',
    }
    export enum location {
        VIRTUAL = 'virtual',
        ONSITE = 'onsite',
        NOT_FIXED = 'not-fixed',
    }
}

