import { DateTime } from 'luxon'

export const monthNames = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']
export const dayNames = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag']
export const dayNamesShort = ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So']

export function getWeekdayName(date: DateTime): string {
    return dayNames[date.weekday - 1]
}
export function getWeekdayNameShort(date: DateTime): string {
    return dayNamesShort[date.weekday - 1]
}

export function getTime(date: DateTime): string {
    return date.toFormat('HH:mm')
}

export function getDate(date: DateTime): string {
    return date.toFormat('dd. MMMM yyyy')
}

export function getDateShort(date: DateTime): string {
    return date.setLocale('de').toFormat('cccc, d. MMMM')
}

export function getDateMedium(date: DateTime): string {
    return date.setLocale('de').toFormat('dd.MM.yyyy')
}
