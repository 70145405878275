'use client'

import { isServer } from '@tebuto/functions'
import { usePathname } from 'next/navigation'
import React, { useContext, useEffect, useRef, useState } from 'react'
import useRouter from './useRouter'

type HistoryURL = string | URL | null | undefined

type RouteChangeStartEvent = CustomEvent<{ targetUrl: string }>
type RouteChangeEndEvent = CustomEvent<{ targetUrl: HistoryURL }>
type ForceAnchorClickEvent = MouseEvent & { isForceAnchorClickEvent: true }

declare global {
    interface WindowEventMap {
        beforeRouteChangeEvent: RouteChangeStartEvent
        routeChangeConfirmationEvent: RouteChangeStartEvent
        routeChangeStartEvent: RouteChangeStartEvent
        routeChangeEndEvent: RouteChangeEndEvent
    }
}

interface FreezeRequestsContextValue {
    freezeRequests: string[]
    setFreezeRequests: React.Dispatch<React.SetStateAction<string[]>>
}

const FreezeRequestsContext = React.createContext<FreezeRequestsContextValue>({
    freezeRequests: [],
    setFreezeRequests: () => {}
})

export const useFreezeRequestsContext = () => {
    const { freezeRequests, setFreezeRequests } = useContext(FreezeRequestsContext)

    return {
        freezeRequests,
        request: (sourceId: string) => {
            setFreezeRequests([...freezeRequests, sourceId])
        },
        revoke: (sourceId: string) => {
            setFreezeRequests(freezeRequests.filter(x => x !== sourceId))
        }
    }
}

type PushStateInput = [data: unknown, unused: string, url: HistoryURL]

export const triggerRouteChangeStartEvent = (targetUrl: string): void => {
    const event = new CustomEvent('routeChangeStartEvent', { detail: { targetUrl } })
    if (!isServer()) window.dispatchEvent(event)
}

export const triggerRouteChangeEndEvent = (targetUrl: HistoryURL): void => {
    const event = new CustomEvent('routeChangeEndEvent', { detail: { targetUrl } })
    if (!isServer()) window.dispatchEvent(event)
}

export const triggerBeforeRouteChangeEvent = (targetUrl: string): void => {
    const event = new CustomEvent('beforeRouteChangeEvent', { detail: { targetUrl } })
    if (!isServer()) window.dispatchEvent(event)
}

export const triggerRouteChangeConfirmationEvent = (targetUrl: string): void => {
    const event = new CustomEvent('routeChangeConfirmationEvent', { detail: { targetUrl } })
    if (!isServer()) window.dispatchEvent(event)
}

const createForceClickEvent = (event: MouseEvent): ForceAnchorClickEvent => {
    const res = new MouseEvent('click', event) as ForceAnchorClickEvent
    res.isForceAnchorClickEvent = true
    return res
}

export const RouteChangesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [freezeRequests, setFreezeRequests] = useState<string[]>([])
    const router = useRouter()

    useEffect(() => {
        const abortController = new AbortController()

        const handleAnchorClick = (event: MouseEvent | ForceAnchorClickEvent) => {
            const target = event.currentTarget as HTMLAnchorElement

            const isFrozen = freezeRequests.length !== 0
            if (isFrozen && !(event as ForceAnchorClickEvent).isForceAnchorClickEvent) {
                event.preventDefault()
                event.stopPropagation()

                window.addEventListener(
                    'routeChangeConfirmationEvent',
                    ev => {
                        if (ev.detail.targetUrl === target.href) {
                            router.push(target.href)
                        }
                    },
                    { signal: abortController.signal }
                )

                triggerBeforeRouteChangeEvent(target.href)
                return
            }

            triggerRouteChangeStartEvent(target.href)
        }

        const handleAnchors = (anchors: NodeListOf<HTMLAnchorElement>) => {
            anchors.forEach(a => {
                a.addEventListener('click', handleAnchorClick, { signal: abortController.signal, capture: true })
            })
        }

        const handleMutation: MutationCallback = mutationList => {
            mutationList.forEach(record => {
                if (record.type === 'childList' && record.target instanceof HTMLElement) {
                    const anchors: NodeListOf<HTMLAnchorElement> = record.target.querySelectorAll('a[href]')
                    handleAnchors(anchors)
                }
            })
        }

        const anchors: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('a[href]')
        handleAnchors(anchors)

        const mutationObserver = new MutationObserver(handleMutation)

        mutationObserver.observe(document, { childList: true, subtree: true })

        const pushStateProxy = new Proxy(window.history.pushState, {
            apply: (target, thisArg, argArray: PushStateInput) => {
                triggerRouteChangeEndEvent(argArray[2])
                return target.apply(thisArg, argArray)
            },
            getPrototypeOf: target => {
                return target
            }
        })

        window.history.pushState = pushStateProxy

        return () => {
            mutationObserver.disconnect()
            abortController.abort()
            window.history.pushState = Object.getPrototypeOf(pushStateProxy)
        }
    }, [freezeRequests])

    return <FreezeRequestsContext.Provider value={{ freezeRequests, setFreezeRequests }}>{children}</FreezeRequestsContext.Provider>
}

interface RouteChangeCallbacks {
    onBeforeRouteChange?: (target: string) => boolean // if `false` prevents a route change until `allowRouteChange` is called
    onRouteChangeStart?: (target: string) => void
    onRouteChangeComplete?: (target: HistoryURL) => void
}

export default function useRouteChangeEvents(callbacks: RouteChangeCallbacks) {
    const id = useRef(Math.random().toString())
    const pathname = usePathname()
    const { request, revoke } = useFreezeRequestsContext()
    const [confirmationTarget, setConfirmationTarget] = useState<string | null>(null)

    useEffect(() => {
        request(id.current)

        return () => revoke(id.current)
    }, [])

    useEffect(() => {
        const abortController = new AbortController()

        window.addEventListener(
            'beforeRouteChangeEvent',
            ev => {
                const { targetUrl } = ev.detail

                const targetPathname = getPathnameFromURL(targetUrl)
                const isSamePage = targetPathname === pathname
                if (isSamePage) {
                    return
                }

                const shouldProceed = callbacks.onBeforeRouteChange?.(targetUrl)
                if (shouldProceed ?? true) {
                    triggerRouteChangeConfirmationEvent(targetUrl)
                } else {
                    setConfirmationTarget(targetUrl)
                }
            },
            { signal: abortController.signal }
        )

        window.addEventListener(
            'routeChangeEndEvent',
            ev => {
                callbacks.onRouteChangeComplete?.(ev.detail.targetUrl)
            },
            { signal: abortController.signal }
        )

        window.addEventListener(
            'routeChangeStartEvent',
            ev => {
                callbacks.onRouteChangeStart?.(ev.detail.targetUrl)
            },
            { signal: abortController.signal }
        )

        return () => abortController.abort()
    }, [callbacks])

    return {
        allowRouteChange: () => {
            if (!confirmationTarget) {
                console.warn('allowRouteChange called for no specified confirmation target')
                return
            }
            triggerRouteChangeConfirmationEvent(confirmationTarget)
        }
    }
}

function getPathnameFromURL(url: string): string {
    try {
        return new URL(url).pathname
    } catch {
        return url
    }
}
