import { DateTime } from 'luxon'
import { User } from './api/clients/models/User'
import { Client } from './api/therapists/models/Client'
import { EventRule } from './api/therapists/models/EventRule'
import { PlaceAddress } from './api/therapists/models/PlaceAddress'
import { getDayTitleByStart } from './functions'

const COMPONENT_TEXTS = {
    forms: {
        reset: 'Verwerfen',
        save: 'Speichern',
        saved: 'Gespeichert',
        multiselect: {
            creatableNoOptionsMessage: 'Tippen Sie um einen Eintrag hinzuzufügen',
            noOptionsMessage: 'Kein Eintrag gefunden',
            formatCreateLabel: (inputValue: string) => `"${inputValue}" erstellen`,
            addPlaceholder: 'Hinzufügen...',
            noOptionsSelectMessage: 'Keine Optionen verfügbar',
            isNew: 'Neu'
        }
    },
    functions: {
        plans: {
            free: 'Kostenlos',
            paid: 'All-Inclusive'
        },
        days: {
            monday: 'Montag',
            tuesday: 'Dienstag',
            wednesday: 'Mittwoch',
            thursday: 'Donnerstag',
            friday: 'Freitag',
            saturday: 'Samstag',
            sunday: 'Sonntag'
        },
        meetingProviders: {
            zoom: 'Zoom',
            microsoftTeams: 'Microsoft Teams'
        },
        errors: {
            defaultMessage: 'Ups, da ist leider etwas schief gelaufen. Bitte versuchen Sie es später erneut.'
        }
    },
    guards: {
        paidPlanRequired: 'Der Tebuto All-Inclusive Plan wird für diese Funktionalität benötigt',
        configuration: {
            title: 'Konfigurieren Sie Ihr Profil',
            description: 'Fügen Sie Informationen hinzu, die für Ihre Patienten relevant sind.',
            manual: {
                title: 'Informationen',
                generalTitle: 'Allgemeine Informationen',
                addressTitle: 'Adresse',
                name: 'Name Ihrer Praxis',
                nameDescription: 'Dies sollte der Name sein, unter dem Ihre Klienten Sie kennen',
                streetAndNumber: 'Straße und Hausnummer',
                additionalInformation: 'Anfahrtsbeschreibung (optional)',
                zip: 'Postleitzahl',
                city: 'Ort',
                importFromGoogle: 'Zurück'
            },
            import: {
                title: 'Aus Google Maps importieren',
                description: 'Wenn Ihr Unternehmen bereits bei Google Maps gelistet ist, können Sie es bequem importieren.',
                searchPlaceholder: 'Unternehmensname eingeben',
                noResults: 'Keine Ergebnisse gefunden',
                import: 'Import ausführen',
                websiteLabel: 'Website',
                phoneLabel: 'Telefon',
                openingsLabel: 'Öffnungszeiten',
                addressLabel: 'Adresse',
                address: (address: PlaceAddress) => address && `${address.streetName} ${address.streetNumber}, ${address.postalCode} ${address.cityName}`,
                optOut: 'Alternative können Sie Ihre Daten auch',
                optOutButtonText: 'manuell eingeben',
                manualInput: 'Manuelle Eingabe'
            },
            errors: {
                mandatory: 'Pflichtangabe',
                invalidZip: 'Ungültige Postleitzahl'
            }
        },
        plan: {
            title: 'Wählen Sie Ihr Abonnement',
            description: 'Sie können Ihr Abonnement später jederzeit ändern.',
            choose: 'Auswählen',
            active: 'Derzeit Aktiv',
            test: '30 Tage kostenlos testen',
            free: {
                name: 'Verzeichnis-Eintrag',
                priceMonthly: 'Kostenlos',
                description: 'Kostenloser Eintrag in unserem Verzeichnis für Psychologen und Psychotherapeuten. Kein Haken, kein Kleingedrucktes. Wir möchten helfen.',
                features: ['Eintrag im Verzeichnis', 'Backlink zur Webseite'],
                notIncluded: ['Online-Terminbuchung']
            },
            paid: {
                name: 'Online-Terminbuchung',
                priceMonthly: '99€',
                priceIndicator: 'pro Monat',
                description: 'Online-Terminbuchung für Psychologen und Therapeuten - mit allen aktuellen und zukünftigen Funktionen. Keine versteckten Kosten.',
                features: ['Eintrag im Verzeichnis', 'Backlink zur Webseite', 'Online-Terminbuchung'],
                testForFree: true
            }
        },
        billing: {
            title: 'Abonnement hinzufügen',
            description: 'Fügen Sie Ihre Zahlungsinformationen hinzu, um Ihr Tebuto-Abonnement zu aktivieren. Sie können Ihr Abonnement jederzeit monatlich kündigen.'
        },
        waitlist: {
            title: 'Warteliste',
            heading: 'Vielen Dank für Ihr Interesse an Tebuto! ',
            description:
                'Unser Produkt befindet sich derzeit in der Beta-Phase und wir beschränken den Zugang um sicherzustellen, dass wir Ihnen die bestmögliche Erfahrung bieten können.',
            why: {
                title: 'Warum eine Warteliste?',
                description:
                    'Da sich Tebuto noch in der Beta-Phase befindet, möchten wir zunächst sicherstellen, dass alles reibungslos funktioniert und unsere hohen Qualitätsstandards erfüllt werden. Wir laden schrittweise neue Benutzer ein, um sicherzustellen, dass wir auf jede Rückmeldung eingehen und Verbesserungen vornehmen können.'
            },
            benefits: {
                title: 'Was erwartet Sie?',
                news: {
                    title: 'Neuigkeiten',
                    description:
                        'Als Teil unserer Warteliste erhalten Sie regelmäßige Updates über den Fortschritt von Tebuto. Sie sind immer auf dem neuesten Stand und erfahren als Erste, wenn es wichtige Entwicklungen gibt.'
                },
                exclusiveAccess: {
                    title: 'Exklusiver Zugang',
                    description:
                        'Sobald Tebuto verfügbar ist oder wir neue Benutzer in unser Beta-Programm aufnehmen, werden Sie von uns informiert und erhalten Zugang. Sie werden unter den ersten Nutzern sein, die Tebuto in vollem Umfang nutzen können.'
                }
            },
            nextSteps: {
                title: 'Wie geht es weiter?',
                description:
                    'Sie brauchen nichts weiter zu tun. Wir halten Sie per E-Mail auf dem Laufenden. Sollten wir Sie für das Beta-Programm auswählen oder Tebuto allgemein verfügbar sein, benachrichtigen wir Sie und Sie können direkt loslegen.'
            },
            stayInContact: {
                title: 'Bleiben wir in Kontakt',
                description:
                    'Wir schätzen Ihre Geduld und Ihr Interesse sehr. Sobald Sie Zugang zu Tebuto haben, freuen wir uns auf Ihr Feedback, um das Produkt weiter zu verbessern. Bis dahin können Sie uns bei Fragen und Anliegen jederzeit erreichen.',
                contact: 'Kontaktformular',
                phoneNumber: '0721 5095 8279'
            },
            end: 'Wir freuen uns darauf, Sie bald bei Tebuto begrüßen zu dürfen!'
        },
        clientTherapistSelection: {
            noTherapist: {
                title: 'Kein:e Therapeut:in vorhanden',
                description1:
                    'Aktuell hat Sie niemand zur Nutzung von Tebuto eingeladen. Das kann entweder bedeuten, dass Ihr:e Therapeut:in Tebuto noch nicht nutzt, oder diese:r Sie noch nicht als Klient hinzugefügt hat.',
                description2: 'Um Ihre und die Daten unserer Therapeut:innen zu schützen können wir dies nicht selbst prüfen.',
                description3:
                    'Bitte wenden Sie sich an Ihre Therapeut:in und erkundigen Sie sich, ob diese:r Tebuto nutzt und Sie bereits mit Ihrer E-Mail als Klient hinzugefügt hat. Alternativ, können Sie eine:n Therapeut:in suchen, die bereits Tebuto nutzt.',
                findTherapists: 'Therapeut:in in Ihrer Nähe suchen'
            },
            therapistsAvailable: {
                title: 'Therapeut:in wählen',
                description: 'Sie sind Klient:in oder Patient:in bei mehreren Therapeuten. Bitte wählen Sie die Praxis in der Sie Ihren Termin buchen möchten.',
                owner: 'Inhaber:in',
                ownerMale: 'Inhaber',
                ownerFemale: 'Inhaberin',
                select: 'Auswählen'
            }
        }
    },
    icons: {
        google: 'Google',
        googleCalendar: 'Google Kalender',
        googleMaps: 'Google Maps',
        microsoftTeams: 'Microsoft Teams',
        microsoftOutlook: 'Microsoft Outlook',
        zoom: 'Zoom',
        yahoo: 'Yahoo'
    },
    layout: {
        startPage: 'Startseite',
        loading: 'Wird geladen ...',
        loadingAnimation: 'Lade-Animation',
        profilePicture: 'Profilbild',
        search: 'Suche',
        close: 'Schließen',
        copyright: `${DateTime.now().year} Tebuto.de, Alle Rechte vorbehalten.`,
        app: {
            company: 'Unternehmen',
            dashboard: 'Dashboard',
            events: 'Termine',
            eventCategories: 'Terminkategorien',
            meetings: 'Online-Meetings',
            calendars: 'Kalender',
            settings: 'Einstellungen',
            clients: 'Klienten',
            appointments: 'Termine',
            communication: 'Kommunikation',
            account: 'Account',
            support: 'Hilfe',
            registry: 'Verzeichnis',
            notifications: 'Benachrichtigungen',
            collapse: 'Einklappen',
            expand: 'Ausklappen',
            logout: 'Logout',
            openSidebar: 'Sidebar öffnen',
            closeSidebar: 'Sidebar schließen'
        },
        client: {
            events: 'Termine',
            yourTherapist: (owner: { gender: User.gender }) =>
                owner.gender === User.gender.MALE ? 'Ihr Therapeut' : owner.gender === User.gender.FEMALE ? 'Ihre Therapeutin' : 'Ihr/e Therapeut:in',
            settings: 'Einstellungen',
            logout: 'Abmelden',
            confirmLogout: 'Möchten Sie sich wirklich abmelden?',
            landing: 'Terminbuchung für Ihr Unternehmen',
            imprint: 'Impressum',
            privacy: 'Datenschutzerklärung',
            confirmLogoutText:
                'Wenn Sie sich ausloggen, müssen Sie sich beim nächsten Mal erneut mit einem Einmalcode anmelden. Sollte es sich um Ihr privates Gerät handeln, ist ein Logout nicht notwendig.'
        },
        landing: {
            footerTitle: 'Footer',
            slogan: 'Terminbuchung für Psycholog:innen und Therapeut:innen',
            toApp: 'Zur App',
            menu: {
                home: 'Startseite',
                aboutUs: 'Über uns',
                contact: 'Kontakt',
                directory: 'Therapeuten-Verzeichnis',
                status: 'Status',
                roadmap: 'Roadmap',
                impress: 'Impressum',
                privacyDeclaration: 'Datenschutzerklärung',
                usage: 'Funktionen',
                price: 'Preis',
                blog: 'Blog',
                testimonials: 'Kundenstimmen',
                waitlist: 'Warteliste',
                faq: 'FAQ',
                theCompany: 'Das Unternehmen',
                support: 'Support',
                links: 'Links',
                login: 'Login',
                law: 'Rechtliches',
                cookieSettings: 'Cookie-Einstellungen',
                features: {
                    appointments: {
                        title: 'Termin-Management',
                        subtitle: 'Termine automatisiert erstellen'
                    },
                    clientLinks: {
                        title: 'Termine mit Klienten teilen',
                        subtitle: 'Passwortlose Links für Klienten'
                    },
                    communication: {
                        title: 'Kommunikation automatisieren',
                        subtitle: 'Terminerinnerungen, Infomaterial und mehr'
                    },
                    directory: {
                        title: 'Optionaler Öffentlicher Eintrag',
                        subtitle: 'Von der Reichweite von Tebuto profitieren'
                    }
                }
            },
            social: {
                linkedIn: 'LinkedIn',
                linkedInTitle: 'Tebuto auf LinkedIn',
                instagram: 'Instagram',
                instagramTitle: 'Tebuto auf Instagram',
                gitHub: 'GitHub',
                gitHubTitle: 'Tebuto auf GitHub'
            }
        }
    },
    modals: {
        closeDialogue: 'Dialog schließen',
        submitButtonText: 'Bestätigen',
        cancelButtonText: 'Abbrechen',
        bookedEventSlideOver: {
            recurring: ({ interval, dateStart }: EventRule) =>
                interval > 0 ? `Jeden ${interval === 1 ? '' : `${interval}.`} ${getDayTitleByStart(DateTime.fromISO(dateStart) as DateTime<true>)}` : 'Keine',
            booking: 'Termin',
            outageFee: 'Ausfallhonorar',
            outageFeeUsed: 'In Anspruch genommen',
            outageFeeNotUsed: 'Nicht in Anspruch genommen',
            status: 'Status',
            showClient: 'Anzeigen',
            unknown: 'Unbekannt',
            actions: 'Aktionen',
            selectedLocation: 'Gewählter Ort',
            inPast: 'Der Termin liegt in der Vergangenheit, er kann nicht mehr bearbeitet werden.',
            skipped: 'Ausgesetzt',
            eventSkipped: 'Termin wurde ausgesetzt',
            buttons: {
                bookManually: 'Manuell vergeben',
                block: 'Reservieren',
                skip: 'Aussetzen',
                release: 'Freigeben',
                joinOnlineMeeting: 'Online-Meeting beitreten',
                editEventRule: 'Bearbeiten',
                acceptRequest: 'Annehmen',
                declineRequest: 'Ablehnen',
                cancelBooking: 'Absagen',
                eventIsOutage: 'Termin ist ausgefallen',
                useOutageFee: 'Ausfallhonorar Fordern',
                skipOutageFee: 'Ausfallhonorar Nicht Fordern',
                deleteEventRule: 'Löschen'
            },
            manualBookingDialogue: {
                title: 'Termin manuell vergeben',
                submitButton: 'Vergeben',
                description:
                    'Sie können Termine auch ganz bequem selbst für Klient:innen buchen. Diese:r wird darüber informiert und kann den Termin auch selbstständig verwalten.',
                client: 'Klient:in',
                where: 'Wo soll der Termin stattfinden?',
                onsite: 'Vor Ort',
                virtual: 'Online',
                couldNotLoadClients: 'Klient:innen konnten nicht geladen werden',
                eventBooked: 'Termin wurde erfolgreich gebucht'
            },
            reserveEventDialogue: {
                title: 'Termin reservieren',
                submitButton: 'Reservieren',
                eventTitle: 'Titel (optional)',
                description:
                    'Falls Sie Termine an Klient:innen vergeben, die Tebuto nicht nutzen, können Sie für diese Termine reservieren. Die Termine sind dann für andere Klient:innen nicht mehr buchbar.',
                eventReserved: 'Termin wurde erfolgreich reserviert'
            },
            releaseEventDialogue: {
                title: 'Termin zur Buchung freigeben',
                submitButton: 'Freigeben',
                description: 'Möchten Sie den Termin wirklich freigeben? Der Termin wird damit wieder für alle Klient:innen buchbar.',
                eventReleased: 'Termin wurde erfolgreich freigegeben'
            },
            rejectBookingDialogue: {
                title: 'Buchung ablehnen',
                description: 'Möchten Sie die Buchung wirklich ablehnen? Der Klient wird darüber informiert und kann sich einen neuen Termin buchen.',
                submitButton: 'Ablehnen',
                messageLabel: 'Nachricht an Klient:in (optional)'
            },
            cancelBookingDialogue: {
                title: 'Buchung absagen',
                description: 'Möchten Sie die Buchung wirklich absagen? Der Klient wird darüber informiert und kann sich einen neuen Termin buchen.',
                submitButton: 'Absagen',
                messageLabel: 'Nachricht an Klient:in (optional)',
                eventBookableAgainLabel: 'Termin wird wieder frei und kann gebucht werden'
            },
            outageDialogue: {
                title: 'Termin als Ausfall markieren',
                submitButton: 'Markieren',
                description: (client: Client, outageFeeEnabled: boolean) =>
                    client &&
                    `${client.firstName} ${client.lastName} ist nicht zum vereinbarten Termin erschienen? Sie können den Termin
                            als Ausfall markieren${outageFeeEnabled && ' und das Ausfallhonorar einfordern wenn Sie das möchten'}.`,
                requireOutageFeeLabel: 'Ausfallhonorar einfordern'
            },
            deleteEventRuleDialogue: {
                title: 'Terminregel löschen',
                description: 'Möchten Sie die Terminregel wirklich löschen? Alle zukünftigen Termine, die nach dieser Regel erstellt wurden, werden ebenfalls gelöscht.',
                submitButton: 'Löschen'
            }
        }
    },
    types: {
        genders: {
            female: 'Frau',
            male: 'Herr',
            diverse: 'Divers',
            notSet: 'nicht angegeben'
        },
        eventStatus: {
            notBooked: 'Nicht gebucht',
            booked: 'Angefragt',
            approved: 'Bestätigt',
            rejected: 'Abgelehnt',
            cancelled: 'Abgesagt',
            outage: 'Ausgefallen',
            reserved: 'Reserviert'
        },
        location: {
            notFixed: 'Vor Ort oder Online',
            onsite: 'Vor Ort',
            virtual: 'Online'
        }
    },
    requestGate: {
        defaultEmptyMessage: 'Keine Einträge vorhanden'
    }
}

export default COMPONENT_TEXTS
