/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CalendarExport } from '../models/CalendarExport';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CalendarExportService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Tebuto Calendar Export URL
     * Get Calendar Export URL
     * @param therapistId
     * @returns CalendarExport
     * @throws ApiError
     */
    public getCalendarExportUrl(
        therapistId: number,
    ): CancelablePromise<CalendarExport> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/therapists/{therapistId}/calendar-export',
            path: {
                'therapistId': therapistId,
            },
        });
    }
    /**
     * Create Tebuto Calendar Export URL
     * Create Calendar Export URL
     * @param therapistId
     * @returns CalendarExport
     * @throws ApiError
     */
    public createCalendarExportUrl(
        therapistId: number,
    ): CancelablePromise<CalendarExport> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/therapists/{therapistId}/calendar-export',
            path: {
                'therapistId': therapistId,
            },
        });
    }
    /**
     * Delete Tebuto Calendar Export URL
     * Delete Calendar Export URL
     * @param therapistId
     * @returns any
     * @throws ApiError
     */
    public deleteCalendarExportUrl(
        therapistId: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/therapists/{therapistId}/calendar-export',
            path: {
                'therapistId': therapistId,
            },
        });
    }
}
