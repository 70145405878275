/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientEvent } from './ClientEvent';
import type { OnlineMeeting } from './OnlineMeeting';
export type ClientBooking = {
    createdAt: string;
    id: number;
    locationSelection: ClientBooking.locationSelection;
    isConfirmed: boolean;
    isOutage: boolean;
    event: ClientEvent;
    onlineMeeting?: OnlineMeeting;
    ics: string;
};
export namespace ClientBooking {
    export enum locationSelection {
        VIRTUAL = 'virtual',
        ONSITE = 'onsite',
        NOT_FIXED = 'not-fixed',
    }
}

