/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientEntity } from './ClientEntity';
import type { DateTime } from './DateTime';
import type { EventEntity } from './EventEntity';
import type { OnlineMeetingEntity } from './OnlineMeetingEntity';
export type BookingEntity = {
    id: number;
    createdAt: DateTime;
    locationSelection: BookingEntity.locationSelection;
    isConfirmed: boolean;
    isOutage: boolean;
    outageFeeApplies: BookingEntity.outageFeeApplies;
    reminderJobId?: string;
    event: EventEntity;
    client: ClientEntity;
    onlineMeeting?: OnlineMeetingEntity;
};
export namespace BookingEntity {
    export enum locationSelection {
        VIRTUAL = 'virtual',
        ONSITE = 'onsite',
    }
    export enum outageFeeApplies {
        YES = 'yes',
        NO = 'no',
        NOT_SELECTED = 'not-selected',
    }
}

